import React from 'react';

const Email = () => {
  return (
    <nav className="email-navbar">
      <i 
        data-sal="slide-up" 
        data-sal-easing="ease" 
        data-sal-duration="500" 
        data-sal-delay="700"
      >
        <a className="external" href="mailto:vo.chris.k@gmail.com">vo.chris.k@gmail.com</a>
      </i>
    </nav>
  );
};

export default Email;