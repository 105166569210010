import React from 'react';

const Footer = () => {
  return (
    <footer>
        <a className="external footer" target="_blank" href="https://github.com/VoChrisK/personal-website">2020 - Designed and built by Chris Vo</a>
    </footer>
  )
};

export default Footer;